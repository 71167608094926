.block-wealth-disclosure {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            width: 100%;
            @include max-wrap-small;
        }
    }
    table {
        table-layout: fixed;
        width: 100%;
        tr {
            border: 1px $notice-color solid;
            display: grid;
            grid-template-rows: 1fr;
            @include tablet {
                display: table-row;
            }
        }
        td {
            font-size: 1.8rem;
            text-align: center;
            color: $body-font-color;
            border: 1px $notice-color solid;
            padding: 0.25em 0.5em;
            // @include tablet {
            //     text-align: left;
            // }
        }
    }
}
