// Listing
.mod-directory-listing {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .section-title {
        margin: 0 auto 0.65em;
    }
    .section-intro {
        margin: 0 auto 3em;
        @include tablet {
            margin: 0 auto 5em;
            max-width: 900px;
        }
        > *:last-child {
            margin: 0 auto;
        }
    }
    .entries {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        gap: 5em 0;
        @include responsive(600px) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 5em 3em;
        }
        .entry {
            margin: 0 auto;
            display: grid;
            gap: 0.5em 0;
            @include responsive(600px) {
                margin: 0;
                flex-basis: calc(50% - 1.5em);
            }
            @include responsive(600px) {
                margin: 0;
                flex-basis: calc(33% - 2em);
            }
            @include tablet {
                flex-basis: calc(25% - 2.3em);
            }
            > *:not(svg) {
                margin: 0 auto;
            }
            > svg {
                margin: 0 auto 1em;
                width: 100%;
                max-width: 205px;
                height: auto;
            }
        }
        .dir-name {}
        .dir-title {}
        .dir-nmls {}
        .dir-location {
            font-size: 1.6rem;
            @include tablet {
                @include fontSize(2);
            }
            a {
                font-size: 1.6rem;
                color: $primary-color;
                font-weight: bold;
                @include tablet {
                    @include fontSize(2);
                }
            }
        }
        &.three-cols {
            .entry {
                @include tablet {
                    flex-basis: calc(33% - 2.3em);
                }
            }
        }
    }
    &.single-first-row {
        .entry:first-of-type {
            @include tablet {
                flex-basis: 100%;
            }
        }
    }
}
