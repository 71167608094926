/* Separate, integrated into the header on the locations listing page. */
:root {
    --beforeElementOpacity: 0;
    --afterElementOpcaity: 1;
}
.location-tabs {
    display: none;
    @include tablet {
        margin: 0 auto 5em;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 0 4em;
    }
    .tab-button {
        margin: 0;
        position: relative;
        text-align: center;
        width: 100%;
        height: 100%;
        color: #80AACC;
        font-family: $heading-font;
        font-weight: bold;
        font-size: 3rem;
        &.active {
            color: $primary-color;
        }
        &:after {
            content: "";
            display: inline-block;
            margin: 0 auto;
            padding: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
            border-radius: 5px;
            background-color: transparent;
            transition: background-color 0.5s ease;
        }
        &.active {
            &:after {
                background-color: $secondary-color;
                transition: background-color 0.5s ease;
            }
        }
    }
}
.mod-location-list {
    margin: 0 auto;
    padding: 0 $mobile-padding;

    > .content {
        margin: 0 auto;
    }

    .location-accordions {
        margin: 0;
        padding: 0;
    }

    .accordion {
        margin: 0 auto;
        border-color: $notice-color;
        border-style: solid;
        border-width: 0 0 1px 0;

        &:first-child {
            border-width: 1px 0 1px 0;
        }

        @include tablet {
            margin: 0 auto;
            border-width: 0;

            &:first-child {
                border-width: 0;
            }
        }
    }

    .accordion-button {
        margin: 0 auto;
        padding: 0.65em 10px;
        position: relative;
        width: 100%;
        font-family: $heading-font;
        font-weight: bold;
        font-size: 1.6rem;
        color: $body-link-color;
        text-align: left;

        @include tablet {
            display: none;
        }

        &:after {
            content: "";
            display: inline-block;
            margin: 0 auto;
            padding: 0;
            position: absolute;
            right: 10px;
            top: calc(50% - 7px);
            width: 30px;
            height: 15px;
            background: url('../Images/header_nav_dropdown-arrow.svg') transparent no-repeat center / 30px 15px;
            transform: scale(1, 1);
            transition: transform 0.5s ease;
        }

        &.active:after {
            transform: scale(1, -1);
            transition: transform 0.5s ease;
        }
    }

    .accordion-content {
        margin: 0 auto;
        padding: 1em 0 3em;
        display: none;

        &.selected {
            @include tablet {
                display: block !important;
            }
        }

        .content-wrapper {
            margin: 0 auto;
            text-align: center;
        }
    }

    .loc-search-area {
        margin: 0 auto 4em;

        .loc-search-title {
            margin: 0 auto 1.25em;
            color: $body-link-color;
            text-align: left;
            @include tablet {
                text-align: center;
            }
        }

        #location-search {
            margin: 0 auto;
            list-style: none;
            padding: 10px 20px 10px 50px;
            background: url('../Images/header_nav_search-field-icon.svg') $white no-repeat 15px center / 22px 23px;
            font-size: 1.6rem;
            border-radius: 50px;
            border-width: 2px;
            border-style: solid;
            border-color: $primary-color;
            color: $body-font-color;
            width: 100%;
            max-width: 100%;
            @include tablet {
                max-width: 500px;
            }

            &:focus, &:hover {
                border-color: $secondary-color;
            }
        }
    }

    .atm-search-area {
        margin: 0 auto 4em;

        .atm-search-title {
            margin: 0 auto 1.25em;
            color: $body-link-color;
            text-align: left;
            @include tablet {
                text-align: center;
            }
        }

        #atm-search {
            margin: 0 auto;
            list-style: none;
            padding: 10px 20px 10px 50px;
            background: url('../Images/header_nav_search-field-icon.svg') $white no-repeat 15px center / 22px 23px;
            font-size: 1.6rem;
            border-radius: 50px;
            border-width: 2px;
            border-style: solid;
            border-color: $primary-color;
            color: $body-font-color;
            width: 100%;
            max-width: 100%;
            @include tablet {
                max-width: 500px;
            }

            &:focus, &:hover {
                border-color: $secondary-color;
            }
        }
    }

    .loc-filter-area {
        margin: 0 auto 4em;
        position: relative;

        .loc-filter-title {
            margin: 0 auto 1em;
            color: $body-link-color;
        }

        .loc-filters {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: left;
            align-items: flex-start;
            gap: 2em 2em;
            overflow: auto;

            li {
                flex-shrink: 0;
            }

            &:before {
                content: " ";
                position: absolute;
                left: -30px;
                bottom: 0px;
                background: yellow;
                display: block;
                width: 3em;
                height: 3.5em;
                /* background: rgb(255,255,255); */
                background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
                z-index: 1;
                opacity: var(--beforeElementOpacity);
            }

            &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0px;
                background: yellow;
                display: block;
                width: 3em;
                height: 3.5em;
                /* background: rgb(255,255,255); */
                background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
                opacity: var(--afterElementOpacity);
                z-index: 1
            }

            @include tablet {
                justify-content: center;

                &:before {
                    content: none;
                }

                &:after {
                    content: none;
                }
            }
        }

        .filter-button {
            margin: 0;
            padding: 0 0 5px 0;
            position: relative;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #80AACC;
            font-weight: bold;
            font-size: 2rem;

            &.active {
                color: $primary-color;
            }

            &:after {
                content: "";
                display: inline-block;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 5px;
                border-radius: 5px;
                background-color: transparent;
                transition: background-color 0.5s ease;
            }

            &.active {
                &:after {
                    background-color: $secondary-color;
                    transition: background-color 0.5s ease;
                }
            }
        }
    }

    #loc-map-area {
        width: 100%;
        height: auto;
        &:not(.map-open) {
            margin: 0 auto;
            #map-canvas {
                height: 0;
            }
        }
        &.map-open {
            margin: 0 auto 4em;
            #map-canvas {
                height: 400px;
            }
        }
    }

    .loc-list {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        grid-gap: 5em 0;

        @include responsive(700px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 6em 3em;
        }

        @include responsive(1000px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        li {
            text-align: left;
            display: grid;
            gap: 0;
        }

        .loc-photo {
            margin: 0 auto 1.5em;
            text-align: center;
            img {
                margin: 0 auto;
                width: 100%;
                max-width: 100%;
                height: auto;
                border-radius: 50px;
            }
        }

        .loc-name {
            margin: 0 0 0.25em;
            color: $body-link-color;
        }

        .loc-distance {
            margin: 0 0 0.5em;
            &:empty {
                display: none;
            }
        }

        .loc-address {
            margin: 0 0 0.5em;
        }

        .loc-phone-numbers {
            margin: 0 0 1.25em;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            a {
                color: #005499;
            }

            > span:after {
                content: "|";
                margin: 0;
                padding: 0 0.5em;
            }

            > span:last-child:after {
                display: none;
            }
        }

        .loc-actions {
            a {
                color: #005499;
            }

            margin: 0;
            text-align: center;

            @include responsive(700px) {
                text-align: left;
            }
        }

        a:not(.ghost-btn) {
            color: #005499;
            text-decoration: underline;
            font-weight: normal;
            font-size: 1.6rem;
        }
    }

    .atm-open-title {
        margin: 0 auto 2em;
        color: $body-link-color;
    }

    .atm-list {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        grid-gap: 5em 0;

        @include responsive(700px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 6em 3em;
        }

        @include responsive(1000px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        li {
            text-align: left;
            display: grid;
            gap: 0;
        }

        .atm-name {
            margin: 0 0 0.25em;
            color: $body-link-color;
        }

        .atm-distance {
            margin: 0 0 0.5em;
            &:empty {
                display: none;
            }
        }

        .atm-address {
            margin: 0 0 1.25em;
        }

        .atm-actions {
            margin: 0;
            text-align: center;

            @include responsive(700px) {
                text-align: left;
            }
        }

        a:not(.ghost-btn) {
            color: $body-link-color;
            text-decoration: underline;
            font-weight: normal;
            font-size: 1.6rem;
        }
    }

    .holiday-hours-title {
        margin: 0 auto 1.5em;
        color: $body-link-color;
    }

    .holiday-hours-table {
        margin: 0 auto 3em;
        width: 100%;
        max-width: 900px;

        thead > tr {
            border-color: $notice-color;
            border-style: solid;
            border-width: 0 0 3px 0;
        }

        tbody > tr {
            border-color: $notice-color;
            border-style: solid;
            border-width: 0 0 1px 0;
        }

        th {
            padding: 0.5em;
            width: 50%;
            text-align: center;
            font-family: $heading-font;
            color: $body-link-color;
            font-weight: bold;
            font-size: 2.4rem;
        }

        td {
            padding: 0.5em;
            width: 50%;
            text-align: center;
            font-size: 2rem;
        }
    }

    .holiday-disclosures {
        margin: 0 auto;
        text-align: left;
        width: 100%;
        max-width: 700px;
    }
}
.mod-location-details {
    margin: 0 auto;

    .loc-link-area {
        margin: 0 auto 2em;
        text-align: center;

        ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: inline-block;

            li {
                margin: 0 auto 0.5em;
                padding: 0 0 0 40px;
                text-align: left;
                font-family: $heading-font;
                font-size: 2.1rem;
                color: $body-font-color;
                font-weight: normal;
                text-decoration: none;

                @include tablet {
                    @include fontSize(3);
                }

                &:first-child {
                    background: url('../Images/body_icon_location-address.svg') transparent no-repeat left center / auto 75%;
                }

                &:last-child {
                    background: url('../Images/body_icon_location-phone.svg') transparent no-repeat left center / auto 75%;
                }

                a {
                    color: $primary-color;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
    }

    .loc-map-area {
        height: 250px;
        width: 100%;
        margin-bottom: 4em;
        position: relative;
        display: flex;
        justify-content: center;
        transition: height .5s ease;


        .map {
            height: 100%;
            width: 100%;
        }

        .expandButton {
            position: absolute;
            bottom: 0;
            margin: 0 auto;
            background-color: $green-background-color;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: .3em 2em;
            cursor: pointer;

            .expand, .collapse {
                font-family: Lato, sans-serif;
                font-size: 1.6rem;
                font-weight: 500;
                color: $white;
                pointer-events: none;
            }

            .collapse {
                display: none;
            }
        }

        &.open {
            height: 800px;
            transition: height .5s ease;

            .expand {
                display: none;
            }

            .collapse {
                display: inline-block
            }
        }
    }

    .loc-details-area {
        margin: 0 auto 6em;
        padding: 0 $mobile-padding;

        > .content {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            gap: 5rem 5rem;

            > * {
                text-align: left;
                min-width: 315px;
            }
        }

        .detail-title {
            margin: 0 auto 1em;
            color: $body-link-color;
        }

        table {
            width: 100%;
        }

        tr {
        }

        th, td, li {
            font-size: 1.6rem;
        }

        th {
            padding: 0 0 0.5em;
            font-weight: bold;
            width: 35%;
        }

        td {
            padding: 0 0 0.5em;
            width: 65%;
        }

        ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            gap: 0;
        }

        li {
            margin: 0;
            padding: 0 0 0.5em;
        }
    }

    .loc-team-area {
        margin: 0 auto;
        text-align: center;

        > .pad {
            margin: 0 auto;
            padding-left: $mobile-padding;
            padding-right: $mobile-padding;

            > .content {
                margin: 0 auto;
            }
        }

        .section-title {
            margin: 0 auto 0.65em;
        }

        .section-intro {
            margin: 0 auto 3em;

            @include tablet {
                margin: 0 auto 5em;
                max-width: 900px;
            }

            > *:last-child {
                margin: 0 auto;
            }
        }

        .entries {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: grid;
            gap: 5em 0;

            @include responsive(600px) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 5em 3em;
            }

            .entry {
                margin: 0 auto;
                display: grid;
                gap: 0.5em 0;

                @include responsive(600px) {
                    margin: 0;
                    flex-basis: calc(50% - 1.5em);
                }

                @include responsive(600px) {
                    margin: 0;
                    flex-basis: calc(33% - 2em);
                }

                @include tablet {
                    flex-basis: calc(25% - 2.3em);
                }

                > *:not(svg) {
                    margin: 0 auto;
                }

                > svg {
                    margin: 0 auto 1em;
                    width: 100%;
                    max-width: 205px;
                    height: auto;
                }
            }

            .dir-name {
            }

            .dir-title {
            }

            .dir-nmls {
            }

            .dir-location {
                font-size: 1.6rem;

                @include tablet {
                    @include fontSize(2);
                }

                a {
                    font-size: 1.6rem;
                    color: $primary-color;
                    font-weight: bold;

                    @include tablet {
                        @include fontSize(2);
                    }
                }
            }
        }
    }
}
