.block-correspondent-banking-category {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
            display: grid;
            gap: 3rem 0;
        }
    }
    svg {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }
    .title {
        margin: 0 auto;
    }
    .body {
        margin: 0 auto;
        > *:last-child {
            margin: 0 auto;
        }
    }
}
