.block-page-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
            display: grid;
            gap: 1em 0;
            width: 100%;
            > * {
                width: 100%;
            }
            > .title {
                margin: 0 auto;
                text-align: center;
            }
            > .copy {
                margin: 0 auto;
                > p > a {
                    color: $primary-color;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                @include tablet {
                    ul {
                        column-count: 2;
                        column-gap: 2em;
                    }
                }
            }
            > .actions {
                margin: 0 auto;
                display: grid;
                grid-gap: .5em;
                grid-template-columns: repeat(auto-fit,minmax(290px, max-content));
                @include max-wrap-small;
            }
        }
    }
    &.text-left, &:not(.text-center):not(.text-right) {
        text-align: left;
        .actions {
            justify-content: left;
        }
    }
    &.text-center {
        text-align: center;
        .actions {
            justify-content: center;
        }
    }
    &.text-right {
        text-align: right;
        .actions {
            justify-content: right;
        }
    }
}
.page-section-two-column {
    > .content {
        svg {
            margin-bottom: 1em
        }
    }
}
