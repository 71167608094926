.block-products-know-more-tabs {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    padding: 0;

    .section-title {
        margin: 0 auto;
        padding: 0 $mobile-padding;
        text-align: center;
    }

    .section-intro {
        margin: 0 auto 3em;
        padding: 0 $mobile-padding;
        font-size: 1.6rem;
        text-align: center;

        > *:last-child {
            margin: 0;
        }
    }

    .product-tabs {
        display: none;

        @include tablet {
            margin: 0 auto;
            padding: 0 $mobile-padding;
            list-style: none;
            display: grid;
            grid-auto-flow: column;
            align-items: stretch;
            width: 100%;
            max-width: 1000px;

            &:empty {
                display: none;
            }
        }

        .tab-button {
            margin: 0;
            padding: 1em 2em 35px;
            border-radius: 15px 15px 0 0;
            position: relative;
            text-align: center;
            width: 100%;
            height: 100%;
            display: grid;
            align-content: flex-start;

            &.active {
                background-color: $tab-background-color;
            }

            &:after {
                content: "";
                display: inline-block;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                left: calc(50% - 15px);
                bottom: 15px;
                width: 30px;
                height: 15px;
                background: url('../Images/header_nav_dropdown-arrow.svg') transparent no-repeat center / 30px 15px;
                transform: scale(1, 1);
                transition: transform 0.5s ease;
            }

            &.active {
                background-color: $tab-background-color;

                &:after {
                    transform: scale(1, -1);
                    transition: transform 0.5s ease;
                }
            }
        }

        li {
            margin: 0;
            padding: 0;
            border-right: 1px $notice-color solid;

            &:first-child {
                border-left: 1px $notice-color solid;
            }

            &.active {
                border-right: 1px transparent solid;

                &:first-child {
                    border-left: 1px transparent solid;
                }
            }

            &.pre-active {
                border-right: none;
            }
        }
    }

    .f-h4 {
        margin: 0 auto 0.5em;
    }

    .prod-description {
        margin: 0 auto 1em;
        font-size: 1.6rem;

        > *:last-child {
            margin: 0;
        }
    }

    .product-accordions {
        margin: 0;
        padding: 0;
    }

    .accordion {
        margin: 0 auto 1em;
        border-color: $notice-color;
        border-style: solid;
        border-width: 1px 0 1px 0;

        @include tablet {
            margin: 0 auto;
            border-width: 0;
        }
    }

    .accordion-button {
        padding: 1em 1em calc(2em + 7px);
        display: block;
        width: 100%;
        position: relative;

        @include tablet {
            &:not(.editmode) {
                display: none;
            }
        }

        @at-root .hydrated#{&} {
            display: block;
        }

        &:after {
            content: "";
            display: inline-block;
            margin: 0 auto;
            padding: 0;
            position: absolute;
            left: calc(50% - 15px);
            bottom: 15px;
            width: 30px;
            height: 15px;
            background: url('../Images/header_nav_dropdown-arrow.svg') transparent no-repeat center / 30px 15px;
            transform: scale(1, 1);
            transition: transform 0.5s ease;
        }

        &.active {
            background-color: $tab-background-color;

            &:after {
                transform: scale(1, -1);
                transition: transform 0.5s ease;
            }
        }
    }

    .accordion-content {
        margin: 0 auto;
        padding: 5rem $mobile-padding;
        background-color: $tab-background-color;
        display: block;

        &.mobile-active, &.desktop-active {
            &:not(.editmode) {
                display: none;
            }
        }

        @at-root html.hydrated#{&} {
            display: block;
        }

        .content-wrapper {
            margin: 0 auto;
            max-width: 1400px;

            @include tablet {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 0 5rem;
                flex-direction: column;
            }
        }

        .details {
            margin: 0 auto;

            @include tablet {
                flex-basis: 100%;
                width: 100%;
                max-width: 100%;
            }

            .mod-rate-table {
                padding: 0 !important;

                th, td {
                    padding: 1em 0.2em !important;
                }
            }
        }

        .details-title {
            margin: 0 0 1em;
        }

        .details-body {
            margin: 0 0 3em;
        }

        .details-rates {
            .content {
                max-width: 100%;
            }

            .table-title, .effective-date, .rate-disclosures {
                text-align: left;
            }

            .rate-actions {
                margin: 0 auto 3rem;
            }
        }

        .actions {
            margin: 0 0 1em;
            padding: 0;
            list-style: none;
            display: grid;
            justify-content: center;
            gap: 1.5em 0;
            text-align: center;

            @include tablet {
                margin: 2em auto;
            }

            a, button {
                width: 100%;
            }
        }

        .bottom-close {
            margin: 0;
            text-align: center;
            display: block;

            &.editmode {
                display: none;
            }

            @include tablet {
                flex-basis: 100%;
                min-width: 100%;
            }

            .accordion-close {
                margin: 0 auto;
                font-size: 1.6rem;
                padding: 0.5em;
                width: 100%;
                text-decoration: none;

                &:before {
                    content: "";
                    margin: 0 0.5em 0 0;
                    position: relative;
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    top: 0.155em;
                    background: url('../Images/body_product-accordion-close.svg') transparent no-repeat center / 17px 17px;
                    transform: scale(1);
                    transition: transform 0.5s ease;
                }

                &:focus, &:hover {
                    text-decoration: underline;

                    &:before {
                        transform: scale(1.15);
                        transition: transform 0.5s ease;
                    }
                }
            }
        }
    }
}
