.block-blog-listing {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .title {
        margin: 0 auto 0.65em;
    }
    .articles {
        margin: 0 auto;
        display: grid;
        gap: 4em 0;
        @include responsive(800px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4em 5em;
        }
        @include responsive(1000px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 4em 5em;
        }
        article {
            margin: 0 auto;
            text-align: left;
            display: grid;
            gap: 1em 0;
            width: 100%;
            text-align: left;
            > * {
                width: 100%;
                line-height: 1.5;
            }
        }
        .article-photo {
            margin: 0 auto;
            height: 48vw;
            max-height: 345px;
            border-radius: 35px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @include responsive(800px) {
                height: 26vw;
                max-height: 250px;
            }
            @include responsive(1000px) {
                height: 16vw;
                max-height: 250px;
            }
        }
        .article-categories {
            margin: 0 auto;
            font-size: 1.8rem;
        }
        .article-title {
            margin: 0 auto;
        }
        .article-link {
            margin: 0 auto;
        }
    }
}
