.block-disclosure {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
        }
    }
    .body {
        margin: 0 auto;
    }
}
