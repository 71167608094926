.block-divider-bar {
    margin: 0 auto;
    padding: 0 $mobile-padding;
    margin-bottom: 2em;
    text-align: center;
    > .content {
        margin: 0 auto;
        @include max-wrap;
        display: grid;
        align-items: flex-start;
        gap: 2rem 0;
        > * {
            margin:  0 auto;
            width: 100%;
        }
    }
    .icon {
        margin: 0 auto;
        position: relative;
        z-index: 2;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 2px);
            width: 100%;
            height: 5px;
            background-color: $notice-color;
            z-index: 1;
        }
        .image-wrapper {
            padding: 0 40px;
            display: inline-block;
            background-color: $white;
            position: relative;
            z-index: 2;
        }
        img {
            width: 85px;
            height: auto;
        }
    }
    .title {}
    .description {
        > *:last-child {
            margin: 0 auto;
        }
    }
}
