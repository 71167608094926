﻿.career-detail {
    > .content {
        @include max-wrap;
        padding: 0 $mobile-padding;

        .copy {
            padding-bottom: 2em;

            .career-apply {
                text-align: center;
                padding-top: 2em;
            }
        }
    }
}

.careers-section {
    padding: 0 $mobile-padding;
    > .content {
        @include max-wrap;
        max-width: 900px;
        .career {
            padding: 2em 0;
            .summary {
                padding-bottom: 1em;
            }
        }
    }
}