.branch-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    img {
        margin-right: .3em;
    }
    span.js-current-branch {
        display: block;
        margin: 0 .5em 0 .5em;
        @include tablet {
            &:after {
                content:'|';
                margin: 0 .5em;
            }
        }
    }
}

.large-branch-selection {
    text-align: center;
    width: 100%;
    padding: 2em 0;
    @include fontSize(2.5);
    color: $primary-color;
    font-weight: 700;

    img {
        margin: 0 auto 1em;
        display: block;
    }
}

.product-rates-section {
    @include tablet {
        padding: 0 $mobile-padding;
    }

    margin-bottom: 5em;

    .content & {
        padding: 0;
    }

    > .content {
        @include max-wrap-small;
        text-align: center;
        position: relative;

        @include mobile {
            &:before {
                content: '';
                width: 30px;
                height: 100%;
                position: absolute;
                z-index: 5;
                left: 0;
                top: 0;
                background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            }

            &:after {
                content: '';
                width: 30px;
                height: 100%;
                position: absolute;
                z-index: 5;
                right: 0;
                top: 0;
                background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            }
        }

        > h2 {
            margin-bottom: 1em;
        }

        .effective-date {
            margin-bottom: 3em;
        }

        .rate-disclosure {
            font-size: 1.4rem;
            font-style: italic;
            text-align: center;
            margin-top: 2em;
        }


        @include mobile {
            .rate-table {
                overflow: auto;
                width: 100%;
            }
        }

        table {
            min-width: 100%;

            @include mobile {
                display: table;
                padding: 0 $mobile-padding;
            }

            tr {
                border-bottom: solid 1px #C6C6C6;

                &:first-of-type {
                    border-bottom: solid 3px $tertiary-color;
                }

                &:last-of-type {
                    border-bottom: none;
                }

                text-align: center;
            }

            td {
                padding: 1.2em .3em;

                @include mobile {
                    min-width: 100px;
                }
            }

            th {
                padding: 1em .3em;
                font-weight: 700;
            }

            p {
                margin: 0;
            }
        }

        .rate-disclosure, .effective-date {
            @include mobile {
                padding: 0 $mobile-padding;
            }
        }

        .rate-disclosure {
            text-align: left;
        }
    }
}
