.block-important-numbers-addresses {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            @include max-wrap;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            gap: 5rem 10rem;
        }
    }
    .category {
        text-align: left;
        max-width: 316px;
    }
    .icon {
        margin: 0;
    }
    .detail-title {
        margin: 0 0 1em;
    }
    .body {
        margin: 0;
        font-size: 1.6rem;
        p, li {
            margin: 0 0 2em;
        }
    }
}
