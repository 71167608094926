.faqs-area {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;

	> .content {
		@include max-wrap;
		//display: grid;
		@include tablet {
			column-count: 2;
			column-gap: 100px;
		}
	}

	.faq {
		break-inside: avoid;
		margin-bottom: 5em;

		h3 {
			margin-bottom: .7em;
		}
	}
}
