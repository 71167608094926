.block-debit-cards {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        position: relative;
        z-index: 1;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            @include tablet {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0 10rem;
            }
        }
    }
    .description {
        margin: 0 auto 3rem;
        @include tablet {
            margin: 0 auto;
        }
        .title {
            margin: 0 auto 0.5em;
        }
        > *:last-child {
            margin: 0;
        }
    }
    .cards {
        margin: 0 auto;
        .intro {
            margin: 0 auto 3rem;
            > *:last-child {
                margin: 0 auto;
            }
        }
        .card-selector {
            margin: 0 auto;
            .card-display {
                width: 100%;
                height: 277px;
                height: auto;
                img {
                    border-radius: 10px;
                }
            }
            .card-listing {
                margin: 0 auto 3em;
                padding: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                gap: 3rem;
            }
            .card-item {
                .card-icon-btn {
                    margin: 0;
                    padding: 0;
                    border-radius: 70px;
                    background-color: $white;
                    border: 5px $white solid;
                    &.active {
                        border: 5px $secondary-color solid;
                    }
                    img {
                        border-radius: 70px;
                    }
                }
            }
        }
    }
}
