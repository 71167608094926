.form-section {
	@extend .block-page-section;



	> .content {
		.form {
			text-align: left;
		}
	}
}


.fsBody .fsSectionHeader {
	background: none !important;
	padding: 0 !important;
}

.fsSectionText h3 {
	margin-bottom: 1em !important;
}

.fsSection table {
	@include fontSize(2);

	.biggest {
		font-size: 22px;
		font-weight: 700;
	}

	tr {
		border-bottom: solid 1px $yellow-background-color;
		td, th {
			padding: .5em 1em .5em 0;
			strong {
				@include fontSize(2.6);
			}
		}
	}
}
