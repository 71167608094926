.cta-area {
	text-align: center;
	margin-bottom: 2em;
	@include tablet {
		/*
		position: sticky;
	    top: 120px;
	    */
	}
    @include mobile {
    	background: $primary-color;
    	position: relative;
    	border-radius: 8px;
    	color: $white;
    	a[class*="ghost-btn"] {
    		color: $white;
    	}
    }
	.actions {
		display: grid;
		grid-gap: 1em;
		margin-bottom: 1em;
	}
	.copy {
		font-size: 1.4rem;
	}

	.cta-header {
		text-align: center;
		padding: 1em .5em;
		font-family: $heading-font;
		font-weight: 700;
		font-size: 1.4rem;
		width: 100%;
		&:after {
			content: '';
			width: 15px;
			height: 8px;
			display: inline-block;
			background: url(../images/arrow-up-mobile.svg) no-repeat;
			background-size: contain;
			margin-left: 7px;
		}
		@include tablet {
			display: none;
		}
		
	}
	&.open .cta-header {
		//display: none;
	}
	.ctas {
		.close-ctas-btn {
			width: 100%;
			padding: 0 .5em .5em;
			text-align: center;
			img {
				transform: rotate(180deg);
			}
		}
		@include mobile {
			display: none;
			padding: 1em;
			position: absolute;
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			background: $primary-color;
    		border-radius: 8px;
		}
		@include tablet {
			display: block !important;
			.js-close-ctas {
				display: none;
			}
		}
	}
}