.icon-info-section {
    padding: 0 $mobile-padding;
    margin-bottom: 3em;
    @include tablet {
        margin-bottom: 2em;
    }

    > .content {
        @include max-wrap;
        display: grid;
        @include tablet {
            grid-template-columns: 100px 1fr;
            align-content: flex-start;
            grid-column-gap: clamp(5px, 1.5em, 50px);
        }
        

        >.icon {
            text-align:left;
            @include tablet {
                text-align: right;
            }
            
            img {
                max-width: 75%;
            }
        }
        >.info {
            h2 {
                margin-top: .5em;
            }
            .copy {
                display: grid;
            }
        }
    }
}

