.carousel-section {
	margin-bottom: 5em;
	padding: 0 $mobile-padding;

	&.center {
		text-align: center;
	}


	.content & {
		padding: 0;
	}

	&.cs-splideAuto > .content  {
		max-width: none;
	}

	> .content {
		@include max-wrap;



		> h2 {

			@include max-wrap-small;
			margin-bottom: .7em;
		}

		> .copy {
			@include max-wrap-small;
			@include fontSize(2);
			margin-bottom: 2em;

			img {
				margin-bottom: .5em;
			}

			ol {
				text-align: left;
			}

			li {
				margin-bottom: 1em;
			}

			@include mobile {
				table {
					ul {
						list-style-position: inside;
					}
				}
			}

			.pagination {
				column-gap: 25px;
				display: flex;
				list-style: none;
				margin-top: 3em;
				flex-wrap: wrap;
				justify-content: center;
			}

			ul {
				@include mobile {
					padding-left: 1em;
				}

				@include tablet {
					column-count: 2;
					column-gap: 80px;

					@at-root .cols-one#{&} {
						column-count: 1;
					}

					@at-root .cols-three#{&} {
						column-count: 3;
					}

					ul {
						text-align: left;
						column-count: 1;
					}
				}

				text-align: left;

				@at-root .center#{&} {
					margin: 0 auto 2em;

					ul {
						margin: 0 0 2em 0;
					}
				}
			}
		}

		> .actions {
			display: grid;
			grid-gap: .5em;
			grid-template-columns: repeat(auto-fit,minmax(290px, max-content));
			@include max-wrap-small;

			&.two-col {
				@include tablet {
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 2em;
					grid-row-gap: 1.5em;
					text-align: left;
				}
			}

			@at-root .center#{&} {
				justify-content: center;
			}
		}
	}

	.explore-area & > .content > .actions {
		justify-content: center;
	}



	.splide__arrow--prev {
		width: 100px;
		height: 100%;
		background: linear-gradient(90deg,#fff 0,rgba(251,251,251,0) 100%);
		border-radius: 0;
		left: 0;
		opacity: 1;
	}

	.splide__arrow--next {
		width: 100px;
		height: 100%;
		background: linear-gradient(270deg,#fff 0,rgba(251,251,251,0) 100%);
		border-radius: 0;
		right: 0;
		opacity: 1;
	}
}
