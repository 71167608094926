.cta-banner {
	background: $primary-color;
	padding: 3em $mobile-padding;
	color: $white;
	text-align: center;

	> .content {
		@include max-wrap;
		@include tablet {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		h2 {
			min-width: 100px;
			color: $white;
			@include tablet {
				margin-bottom: 0;
				margin-right: 1em;
			}
			p {
				margin: 0;
			}
		}
	}
}