﻿.block-page-section {
    table {
        th {
            background: rgb(236,236,236);
        }

        td, th {
            border: 1px solid rgb(221,221,221);
            padding-left: .5rem;
            padding-right: .5rem;
        }
        tr:nth-child(even) {
            td {
                background: rgb(236,236,236);
            }
        }
    }
}
