.icons-banner {
	padding: 5em $mobile-padding;
	text-align: center;

	.ktc-section & .icons {
		display: block !important;

		> .ktc-widget-zone > .ktc-admin-ui {
			display: none;
		}

		> .ktc-widget-zone--empty > .ktc-admin-ui {
			display: block !important;
		}
	}

	> .content {
		@include max-wrap;
	}

	@keyframes popUp {
		0% {
			transform: translateY(40px);
		}

		80% {
			transform: translateY(-10px);
		}

		100% {
			transform: translateY(0px);
		}
	}

	h2 {
		margin-bottom: .5em;
		color: $primary-color;
		@include max-wrap-small;
	}

	.copy {
		margin-bottom: 4em;
		@include max-wrap-small;
	}

	.icons, .icons > .ktc-widget-zone {

		> .ktc-widget-zone {
			width: 100%;

			&:before, &:after {
				content: none;
			}
		}

		transform: translateY(40px);

		@at-root .active#{&} {
			.icon-block-small, .icons-block {
				transform: translateY(40px);
				animation: popUp .5s ease;
				animation-fill-mode: forwards;

				@for $i from 1 through 20 {
					&:nth-child(#{$i}) {
						animation-delay: #{1 + ($i * .1)}s;
					}
				}
			}
		}

		display: grid;
		grid-gap: 3em;
		justify-content: center;

		@include tablet {
			grid-gap: 5em;
			grid-template-columns: repeat(auto-fit,minmax(min-content,130px));

			@at-root .large#{&} {
				grid-gap: 8em;
				grid-template-columns: repeat(auto-fit,minmax(min-content,300px));
			}
		}

		@at-root .large#{&} {
			.icons-block {
				max-width: 300px;

				img {
					max-width: 190px;
				}
			}
		}
	}
}

.icon-block-small, .icons-block linkbox {
	text-align: center;
	max-width: 130px;
	img {
		margin-bottom: 1.5em;
		width: 100%;
	}
	.info {
		a { 
			color: $secondary-color; 
			text-decoration: none;
		}
	}

	&.horizontal {
		text-align: left;
		max-width: none;
		display: grid;
		align-items: center;
		grid-template-columns: 50px auto;
		grid-gap: 15px;
		img {
			margin: 0;
		}
		
		.info {
			a { 
				@include fontSize(2);
				color: $white;
				line-height: 1.3;
				display: block;
			}
		}
	}
}