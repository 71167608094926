.block-video {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            @include tablet {
                display: grid;
                grid-template-columns: 0.4fr 0.6fr;
                gap: 100px;
            }
        }
    }
    .info {
        margin: 0 auto 3em;
        padding: 0;
        @include tablet {
            margin: 0;
            padding: 1em 0 0 0;
        }
        .title {
            margin: 0 0 0.5em;
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
        .body {
            margin: 0 auto 1.5em;
            text-align: left;
            > *:last-child {
                margin: 0;
            }
        }
        .action {
            margin: 0;
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
    }
    .video {
        .video-thumbnail-button {
            border-radius: 100px;
            position: relative;
            z-index: 1;
            min-height: 240px;
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 125px;
                height: 125px;
                transform: translate(-50%, -50%);
                background: url('../Images/body_icon_video-thumbnail-play.svg') transparent no-repeat center / 125px 125px;
                z-index: 2;
            }
        }
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            border-radius: 4em;
        }
    }
}
