.block-helpful-info {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .icon {
        margin: 0 auto 3rem;
        img {
            margin: 0 auto;
            width: 100%;
            max-width: 70px;
            height: auto;
        }
    }
    .title {
        margin: 0 auto 1em;
    }
    .actions {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        gap: 1em 0;
        @include tablet {
            display: inline-grid;
            grid-template-columns: 1fr 1fr;
            gap: 1em 4em;
            &.editmode {
                grid-template-columns: 1fr;
            }
        }
        li {
            text-align: center;
            > a {
                width: 100%;
            }
            > a:not([class]) {
                color: $primary-color;
                font-weight: 700;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            @include tablet {
                text-align: left;
            }
        }
        .cta-buttons.js-editor-cta-buttons > a {
            position: relative;
            display: block;
        }
    }
}
