.block-page-header {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    text-align: center;
    display: grid;
    gap: 3rem 0;
    overflow: hidden;
    .breadcrumb-content {
        .breadcrumbs {
            margin: 32px auto 0;
            padding: 0.5em 1em;
            border-radius: 50px;
            align-items: center;
            display: none;
            @include tablet {
                display: inline-flex;
            }
            > *:last-child:after {
                display: none;
            }
        }
        span, a {
            font-size: 1.2rem;
            font-weight: normal;
        }
        span:after {
            content: "|";
            margin: 0 0.5em;
            position: relative;
            display:inline-block;
        }
        a {
            text-decoration: none;
            font-weight: normal;
            &:focus, &:hover {
                text-decoration: underline;
                font-weight: normal;
            }
        }
    }
    &:not(.has-image) {
        .breadcrumb-content {
            .breadcrumbs {
                background-color: #E3E8F5;
            }
            span, a {
                color: $primary-color;
            }
            a {
                &:focus, &:hover {
                    color: $primary-color;
                }
            }
        }
    }
    &.has-image {
        .breadcrumb-content {
            position: relative;
            svg {
                width: 150%;
                height: auto;
                transform: translate(-15%, 0);
                @include tablet {
                    width: 100%;
                    transform: translate(0, 0);
                }
                image {
                    width: 100%;
                    height: auto;
                }
            }
            .breadcrumbs {
                margin: 32px auto 0;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                background-color: #00000066;
            }
            span, a {
                color: $white;
            }
            a {
                &:focus, &:hover {
                    color: $white;
                }
            }
        }
        .content {
            padding: 0 $mobile-padding $mobile-padding;
        }
    }
    .content {
        margin: 0 auto;
        padding: $mobile-padding;
        .blog-categories {
            margin: 0 auto 0.5em;
            color: $body-font-color;
            font-size: 1.8rem;
        }
        .page-title {
            margin: 0 auto 0.5em;
            color: $body-link-color;
        }
        .intro {
            margin: 0 auto;
            font-size: 1.8rem;
            width: 100%;
            max-width: 700px;
            > *:last-child {
                margin: 0;
            }
        }
    }
    .jumplinks {
        margin: 0 auto;
        padding: 0 $mobile-padding;
        list-style: none;
        display: grid;
        gap: 1em 0;
        @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 1.5em 2em;
        }
        button {
            display: inline-block;
            position: relative;
            padding: 0 1em 0 0;
            font-size: 2rem;
            font-weight: bold;
            color: $primary-color;
            text-decoration: none;
            &:after {
                content: "";
                margin: 0;
                padding: 0;
                position: absolute;
                right: 0;
                top: calc(50% - 6px);
                width: 12px;
                height: 15px;
                background: url('../Images/body_jumplink-arrow.svg') transparent no-repeat center / 12px 15px;
            }
            &:focus, &:hover {
                text-decoration: underline;
                &:after {
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-name: jumplink-arrow;
                }
            }
        }
    }
}

@keyframes jumplink-arrow {
    0% {
        transform: translateY(0) rotateY(0deg);
    }
    25% {
        transform: translateY(25%) rotateY(90deg);
    }
    50% {
        transform: translateY(0) rotateY(180deg);
    }
    75% {
        transform: translateY(-25%) rotateY(270deg);
    }
    100% {
        transform: translateY(0) rotateY(360deg);
    }
}
