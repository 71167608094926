.section-2-col {
    padding: 0 $mobile-padding;
    margin-bottom: 5em;
    > .content {
        @include max-wrap;
        display: grid;
        @include tablet {
            grid-template-columns: 1fr 1fr;
        }
        grid-gap: 40px;
    }
    .content & {
        padding: 0;
    }
}