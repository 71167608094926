.block-product-info {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            display: grid;
            gap: 3rem 0;
            @include tablet {
                grid-template-columns: 0.5fr 1fr;
                gap: 0 7.3rem;
            }
        }
    }
    .prod-photo {
        margin: 0;
        text-align: center;
        svg {
            margin: 0 auto;
            width: 100%;
            max-width: 378px;
            height: auto;
        }
    }
    .prod-details {
        margin: 0;
        padding: $mobile-padding 0 0 0;
        display: grid;
        gap: .5rem 0;
        .prod-title {
            margin: 0;
        }
        .prod-content-accordion {
            margin: 0;
            padding: 0;
            position: relative;
            height: auto;
            overflow: hidden;
            transition: max-height 0.5s linear;
            &:not(.editmode) {
                max-height: 16em;
            }
            &:not(.editmode).overflown:after {
                content: "";
                position: absolute;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 141px;
                left: 0;
                bottom: 0;
                pointer-events: none;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                transition: background 0.5s ease;
            }
            &:not(.editmode).overflown.active {
                max-height: 100em;
                transition: max-height 0.5s linear;
            }
            &:not(.editmode).overflown.active:after {
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                transition: background 0.5s ease;
            }
            .mod-rate-table {
                padding: 0;
            }
        }
        .prod-actions {
            margin: 0;
            padding: $mobile-padding 0 0 0;
            border-top: 1px $notice-color solid;
            text-align: center;
            margin-bottom: 2em;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                align-items: stretch;
                gap: 2rem 2rem;
                li {
                    margin: 0;
                    flex-basis: 200px;
                    width: 100%;
                    max-width: 200px;
                    a, button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                    button {
                        > .open {
                            display: inline;
                        }
                        > .close {
                            display: none;
                        }
                        &.active {
                            > .open {
                                display: none;
                            }
                            > .close {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
        .prod-disclosure {
            margin: 0;
            text-align: center;
            > *:last-child {
                margin: 0;
            }
        }
    }
}
