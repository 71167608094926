.product-grid {
	margin-bottom: 5em;
	padding: 0 $mobile-padding;

	.content & {
		padding: 0;
	}

	.ktc-section & .blocks {
		display: block !important;

		> .ktc-widget-zone > .ktc-admin-ui {
			display: none;
		}

		> .ktc-widget-zone--empty > .ktc-admin-ui {
			display: block !important;
		}
	}

	> .content {
		@include max-wrap;

		> h2 {
			text-align: center;
			margin-bottom: 2em;
		}

		.blocks, .blocks > .ktc-widget-zone {

			> .ktc-widget-zone {
				width: 100%;

				&:before, &:after {
					content: none;
				}
			}

			display: grid;
			grid-gap: 30px;
			justify-content: center;

			@include tablet {
				grid-row-gap: 60px;
				grid-template-columns: repeat(auto-fit,minmax(30%,440px));

				@at-root .col-2#{&} {
					max-width: 1000px;
					margin: 0 auto;
				}
			}

			@at-root .boxed#{&} {
				.product-block {
					border: solid 1px $yellow-background-color;
					border-radius: 15px;
					padding: 30px;
				}
			}
		}
	}

	
}

.product-block {
	opacity: 0;
	transform: translateY(40px);

	@at-root .active#{&} {
		opacity: 1;
		transform: translateY(0px);
		transition: all .5s ease;
	}

	.photo {
		margin-bottom: 1em;

		img {
			width: 100%;
			border-radius: 15px;
		}
	}

	.info {
		@at-root .boxed#{&} {
			padding: 1.5em;
		}

		> h2 {
			margin-bottom: 1em;
		}

		.copy {
			margin-bottom: 2em;
		}

		.actions {
			display: grid;
			grid-gap: 20px;
			text-align: center;

			.cta-lnk, .cta-lnk-blue {
				@include fontSize(3.5);
			}
		}
	}
}