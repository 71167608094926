.team-area {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;

	> .content {
		@include max-wrap;
	}

	.content & {
		padding: 0;
	}

	&.no-photos .team {
		@include tablet {
			grid-template-columns: repeat(auto-fit,minmax(200px,calc(25% - 40px)));
			justify-content: center;
			text-align: center;

			.employee {
				justify-content: center;
			}
		}
	}

	.team {
		display: grid;
		grid-gap: 40px;
		grid-row-gap: 80px;

		@include mobile {
			justify-content: center;
		}

		@include tablet {
			grid-template-columns: repeat(auto-fit,minmax(200px,calc(50% - 40px)));
		}
	}

	.employee {
		display: flex;

		@include mobile {
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		&.highlighted {
			background: #deba6630;
			padding: 1em;
			border-radius: 50px 0;

			.location-name {
				font-weight: 700;
			}
		}

		> img {
			width: 100%;
			max-width: 300px;
			border-radius: 40px 0;

			@include tablet {
				width: 35%;
				max-width: 300px;
				margin-right: 2em;
				height: auto;
				align-self: flex-start;
			}
		}

		.info {
			margin-top: 1em;

			h2 {
				margin-bottom: .2em;
			}

			h3 {
				@include fontSize(1.8);
			}

			.contact {

				.location-name {
					&:after {
						content: ',';
						display: inline-block;
					}

					&:last-of-type:after {
						content: none;
					}
				}
			}

			.actions {
			}
		}
	}
}

.team-filters {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;
	> .content {
		@include max-wrap-small;
	}
	.filters {
		@include tablet {
			column-count: 3;
			column-gap: 50px;
		}

		h3 {
			text-transform: uppercase;
			@include fontSize(2.2);
			margin-bottom: .5em;
			color: $black;
			@include mobile {
				padding: 0 2em;
			}

			&.space-top {
				margin-top: 2em;
			}

		}
		button {
			@include fontSize(2);
			margin-bottom: .5em;
			display: block;
			width: 100%;
			text-align: left;
			@include mobile {
				padding: 0 2em;
			}
		}
	}
}

.employee-header {
	padding: 0 $mobile-padding 0;
	margin-bottom: 5em;
	@include mobile {
		padding-top: 2em;
	}
	> .content {
		@include max-wrap;

		h1 {
			margin-bottom: .5em;
		}
		.employee {
			display: flex;
			align-items: flex-start;
			@include mobile {
				flex-direction: column;
				text-align: center;
    			align-items: center;
			}
			> img {
				width: 100%;
				max-width: 300px;

				@include tablet {
					width: 35%;
					max-width: 300px;
					margin-right: 2em;
				}
				border-radius: 50px 0;
			}
			.info {
				margin-top: 1em;
				width: 100%;
				h2 {
					margin-bottom: .2em;
				}
				.contact {
					margin-bottom: 1em;
				}
				.location-list {
					span:after {
						content: ',';
					}
					span:last-of-type:after {
						content: none;
					}
				}
				.actions, .actions > .row {
					display: grid;
					grid-template-columns: repeat(auto-fit,minmax(250px,300px));
					grid-gap: 1em;
					@include mobile {
					    justify-content: center;
					}
				}
			}
		}
	}

	.breadcrumb {
	    margin-top: 3em;
	    margin-bottom: 3em;
	    font-size: 1.4rem;
	    width: 100%;
	    max-width: 700px;
	    @include mobile {
	    	display: none;
	    }
		a, span {
			color: $primary-color;
			text-decoration: none;
			display: inline-block;
			&:after {
				content: '|';
				margin: 0 .7em;
			}
			&:last-of-type:after {
				content: none;
			}
		}
	}
}

.directory-info {
	.fsBody {
		padding: 0;
		background: none;

		form {
			background: none;
			padding: 0;
			color: $white;
			margin: 0;
		}
	}

	.fsBody .fsLabel, .fsBody label {
		color: $white !important;
		font-weight: 700;
		background: none !important;
	}

	.fsSubmitButton {
		background: $tertiary-color !important;
		color: $black !important;
		padding: 1em 2em;
		
	}

	.fsForm .fsSubmit {
		padding: 0;
		margin: 30px 0 0;
		text-align: left;
	}
}