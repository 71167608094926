.tabs-nav {
	@include mobile {
		display: none;
	}
	border-bottom: solid 1px $tertiary-color;
	> .content {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
	}
	margin-bottom: 2em;
	button {
		color: $primary-color;
		font-family: $heading-font;
		font-weight: 500;
		margin: 0;
		padding: .25em;
		margin: 0 1em;
		@include fontSize(3);
		&.active {
			border-bottom: solid 6px $tertiary-color;
			font-weight: 700;
		}
	}
}
.tab-area {
	@include tablet {
		display: none;
		margin-bottom: 4em;
	}

	.ktc-section & {
		display: block !important;

		.tab-content {
			display: block !important;
		}
	}

	@include mobile {
		border-bottom: solid 1px $tertiary-color;

		.tab-name {
			text-align: center;
			display: block !important;
			width: 100%;
			padding: .5em;
			color: $primary-color;
			font-size: 2.5em;
			font-family: $heading-font;
			font-weight: 500;
			border-top: solid 1px $tertiary-color;

			&:after {
				content: '';
				width: 17px;
				height: 8px;
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
				background: url(../images/arrow-down.svg) no-repeat;
				background-size: contain;
				transition: transform .5s ease;
			}

			@at-root .active#{&} {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		.tab-content {
			display: none;
		}
	}

	&.active {
		display: block;
		@include tablet {
			.tab-content {
				display: block !important;
			}
		}
	}
}