// Featured Blog
.mod-blog-featured {
    margin: 0 auto 3em;
    padding: 0 $mobile-padding;
    > .content {
        margin: 0 auto;
        @include max-wrap;
        display: grid;
        gap: 1.5em 0;
        @include tablet {
            grid-template-columns: 0.6fr 0.4fr;
            align-content: center;
            gap: 0 2em;
        }
    }
    .photo {
        margin: 0;
        svg {
            width: 100%;
            transform: translate(0, 0);
            height: auto;
            @include tablet {
                width: calc(100% + 100px);
                transform: translate(-100px, 0);
            }
        }
    }
    .details {
        margin: 0;
        display: grid;
        gap: 1em 0;
        .featured-article-categories {
            margin: 0;
            font-size: 1.8rem;
            font-weight: bold;
        }
        .featured-article-title {
            margin: 0;
        }
        .feature-article-description {
            margin: 0;
            > *:last-child {
                margin: 0;
            }
        }
        .featured-article-link {
            margin: 0;
        }
    }
}

// Category Filtering
.mod-blog-filters {
    margin: 0 auto 3em;
    padding: 0 $mobile-padding;
    > .content {
        margin: 0 auto;
        @include max-wrap;
        text-align: center;
    }
    .filter-title {
        margin: 0 auto 1em;
        font-family: $body-font;
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.5;
        color: $body-font-color;
    }
    .filter-list {
        margin: 0 auto 5em;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 0 2.5em;
        .filter-button {
            margin: 0;
            padding: 0 0 3px 0;
            position: relative;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #80AACC;
            font-family: $body-font;
            font-weight: bold;
            font-size: 2rem;
            &.active {
                color: $primary-color;
            }
            &:after {
                content: "";
                display: inline-block;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 5px;
                border-radius: 5px;
                background-color: transparent;
                transition: background-color 0.5s ease;
            }
            &.active {
                &:after {
                    background-color: $secondary-color;
                    transition: background-color 0.5s ease;
                }
            }
        }
    }
}

// Article listing
.mod-blog-listing {
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto 5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            text-align: center;
            display: grid;
            gap: 4em 0;
            @include responsive(800px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 4em 5em;
            }
            @include responsive(1000px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 4em 5em;
            }
        }
    }
    article {
        margin: 0 auto;
        text-align: left;
        display: grid;
        gap: 1em 0;
        width: 100%;
        text-align: left;
        > * {
            width: 100%;
            line-height: 1.5;
        }
    }
    .article-photo {
        margin: 0 auto;
        height: 48vw;
        max-height: 345px;
        border-radius: 35px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include responsive(800px) {
            height: 26vw;
            max-height: 250px;
        }
        @include responsive(1000px) {
            height: 16vw;
            max-height: 250px;
        }
    }
    .article-categories {
        margin: 0 auto;
        font-size: 1.8rem;
    }
    .article-title {
        margin: 0 auto;
    }
    .article-link {
        margin: 0 auto;
    }
}

// Individual Blog Pages
.mod-blog-item {
    margin: 0 auto 5em;
    > .pad {
        margin: 0 auto;
        padding: 0 $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    img {
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 35px;
    }
}

// The share section on blog detail pages
.blog-share-section {
    margin: 0 auto 5em;
    padding: 0 $mobile-padding;
    text-align: center;
    > .content {
        margin: 0 auto;
        @include max-wrap;
    }
    .share-title {
        margin: 0 auto 1em;
        font-family: $body-font;
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.5;
        color: $body-font-color;
    }
    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em 1em;
    }
    a {
        transform: scale(1);
        transition: transform 0.5s ease;
        &:focus, &:hover {
            transform: scale(1.15);
            transition: transform 0.5s ease;
        }
    }
}

// The Additional Articles at the bottom of a blog detail page
.mod-blog-additional-articles {
    margin: 0 auto 5em;
    padding: 0 $mobile-padding;
    > .pad {
        > .content {
            margin: 0 auto;
            @include max-wrap;
            text-align: center;
        }
    }
    .articles {
        display: grid;
        gap: 4em 0;
        @include responsive(800px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4em 5em;
        }
        @include responsive(1000px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 4em 5em;
        }
    }
    article {
        margin: 0 auto;
        text-align: left;
        display: grid;
        gap: 1em 0;
        width: 100%;
        text-align: left;
        > * {
            width: 100%;
            line-height: 1.5;
        }
    }
    .article-photo {
        margin: 0 auto;
        height: 48vw;
        max-height: 345px;
        border-radius: 35px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include responsive(800px) {
            height: 26vw;
            max-height: 250px;
        }
        @include responsive(1000px) {
            height: 16vw;
            max-height: 250px;
        }
    }
    .article-categories {
        margin: 0 auto;
        font-size: 1.8rem;
    }
    .article-title {
        margin: 0 auto;
    }
    .article-link {
        margin: 0 auto;
    }
}
