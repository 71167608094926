﻿.beavercreek {


    &.grid-view {
        max-width: 400px;
        width: 100%;
        text-align: center;

        .photo {
            margin-bottom: 1em;
            img {
                border-radius: 35px 0;
            }
        }

        h2 {
            margin-bottom: .5em;
            @extend .f-h3;
        }

        .sub-actions {
            margin: 0;
        }
    }
}
