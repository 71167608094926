.photo-banner {
	@include tablet {
		padding: 0 $mobile-padding;
	}
	margin-bottom: 5em;

	
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;

	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($primary-color,.7);
		opacity: attr(data-opacity);

		z-index: 2;
	}
	@include mobile {
		&:after {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 65vw;
			background: inherit;

			z-index: 1;
		}
	}

	@keyframes dogAnimation {
		0% {
			transform: translate(0);
		}
		10% {
			transform: translate(-30px,0px);
		}
		20% {
			transform: translate(-30px,0px);
		}
		30% {
			transform: translate(-60px,0px);
		}
		40% {
			transform: translate(-60px,0px);
		}
		50% {
			transform: translate(-60px,0px) scaleX(-1);
		}
		60% {
			transform: translate(-60px,0px) scaleX(-1);
		}
		70% {
			transform: translate(-30px,0px) scaleX(-1);
		}
		80% {
			transform: translate(-30px,0px) scaleX(-1);
		}
		90% {
			transform: translate(0px,0px) scaleX(-1);
		}
	}

	> .content {
		@include max-wrap;
		position: relative;
		z-index: 3;

		display: grid;
		@include tablet {
			grid-template-columns: 1fr 1fr;
			grid-gap: 80px;
		}
		align-items: center;

		.info {
			color: $white;
			order: 2;
			background-color: $primary-color;
			padding: $mobile-padding;

			@include tablet {
				order: 1;
				background-color: transparent;
				padding: 0;
			}

			h2 {
				color: $white;
				margin-bottom: .7em;
			}
			.copy {
				margin-bottom: 2em;
				@include fontSize(1.8);
				&:last-child {
					margin-bottom: 0;
				}
			}
			.actions {
				display: grid;
				grid-template-columns: repeat(auto-fit,minmax(290px,max-content));
				grid-gap: .5em;
			}
		}
		.photo {
			@at-root .dog-banner#{&} {
				animation: dogAnimation 10s infinite;
				
			}
			height: 65vw;
			text-align: center;
			img {
				max-height: 100%;

			}

			order: 1;

			@include tablet {
				order: 2;
				height: auto;
			}
		}

	}

	.content & {
		padding: 0;
	}


}