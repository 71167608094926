.block-jump-ribbon {
    margin: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: auto;
    bottom: 5%;
    z-index: 1000;
    background-color: $green-background-color;
    border-radius: 20px 0 0 20px;
    transform: translate(calc(100% - 15px - 1em), -50%);
    transition: transform 0.5s ease;
    @include tablet {
        top: 50%;
        bottom: auto;
        padding: 1.25em;
        transform: translate(calc(100% - 15px - 2.5em), -50%);
    }
    button {
        pointer-events: none;
    }
    &:focus, &:hover {
        transform: translate(0, -50%);
        transition: transform 0.5s ease;
        button {
            padding: 0 calc(10px + 1em) 0 0;
            transition: padding 0.5s ease;
            pointer-events: auto;
            @include tablet {
                padding: 0.25em calc(15px + 1.25em) 0.25em 0;
            }
        }
        .indicator {
            left: calc(100% - 15px);
            transition: left 0.5s ease;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em 0;
        li {
            margin: 0;
            position: relative;
        }
        button {
            margin: 0;
            padding: 0 0 0 calc(10px + 1em);
            color: $white;
            font-size: 1.8rem;
            font-weight: bold;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            transition: padding 0.5s ease;
            @include tablet {
                padding: 0.25em 0 0.25em calc(15px + 1.25em);
            }
        }
        .indicator {
            margin: 0;
            padding: 0;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 0;
            width: 10px;
            height: 10px;
            transform: translate(0, -50%);
            background-color: $white;
            border-radius: 10px;
            transition: left 0.5s ease;
            @include tablet {
                width: 15px;
                height: 15px;
                border-radius: 15px;
            }
        }
    }
}
