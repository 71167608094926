.contactFormSection {
    > .content form {
        padding: 0 $mobile-padding;
        width: 100%;
        margin-bottom: 2em;

        .form-field {
            flex-grow: 1;

            label {
                padding-left: 1em;
            }

            input, select, textarea {
                border: 2px solid $form-input-border;
                border-radius: 30px;
                padding: .5em 1em;
                font-size: 1.8rem;
            }

            input, select {
                height: 50px;
                width: 100%;
            }

            textarea {
                width: 100%;
            }
        }

        input[type=submit] {
            @extend .cta-btn;
            display: block;
            margin: 0 auto;
        }
    }
    .col-right {
        > .fr-view {
            h3 {
                font-size: 2.4rem;
            }
            div {
                margin-bottom: 1em;
                p {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                }
                h4 {
                    margin-bottom: 0;
                    margin-top: .5em;
                }
            }
        }
    }
}
