.block-photo-split {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3.5em;

	.ktc-widget-body-wrapper & {
		> .content > .info > .actions {
			display: block;
		}
	}

	> .pad {
		margin: 0 auto;
		padding-left: $mobile-padding;
		padding-right: $mobile-padding;

		> .content {
			margin: 0 auto;
			@include max-wrap;
			display: grid;
			gap: 3rem 0;

			@include tablet {
				grid-template-columns: 0.5fr 1fr;
				gap: 0 7.3rem;
			}
			> .photo {




				img {
					width: 100%;
					border-radius: 15px;
				}

				&.bottom {
					align-self: flex-end;

					@include mobile {
						border-bottom: solid 1px $tertiary-color;
					}
				}

				&.top {
					align-self: flex-start;
				}

				opacity: 0;
				transform: translateX(-80px);
				transition: all .5s ease;
				transition-property: opacity,transform;

				@at-root .photo-right#{&} {
					transform: translateX(80px);
				}

				@at-root .active#{&} {
					opacity: 1;
					transform: translateX(0);
					transition: all .5s ease;
					transition-property: opacity,transform;
				}
			}

			> .info {
				text-align: center;

				img {
					margin-bottom: .5em;
				}

				@include mobile {
					.buttons, .actions {
						display: grid;
						grid-gap: 10px;
					}

					.actions {
						margin-bottom: 1em;
					}
				}

				@include tablet {
					padding: 2em 0px 2em 0;
					text-align: left;

					@at-root .no-photo#{&} {
						display: flex;
						width: 100%;
						max-width: 1000px;
						margin-left: 0;

						.copy-holder {
							width: 100%;
						}

						.actions-holder {
							min-width: 250px;
							margin-left: 60px;
							margin-top: 70px;

							.btn-actions, .buttons, .actions, .actions #ctaButtons {
								grid-template-columns: none;
							}
						}
					}

					h2 {
						margin-bottom: .5em;
					}

					.copy {
						@include fontSize(2);
						margin-bottom: 2em;
					}

					.actions, .actions #ctaButtons {
						display: grid;

						@include tablet {
							grid-template-columns: max-content;
						}

						grid-row-gap: 20px;
						grid-column-gap: 20px;
						margin-bottom: 2em;
					}

					.btn-actions, .buttons {
						display: grid;
						grid-template-columns: repeat(auto-fit,minmax(200px,max-content));
						grid-row-gap: 30px;
						grid-column-gap: 20px;
						margin-bottom: 2em;

						@include mobile {
							grid-row-gap: 15px;
							justify-content: center;
						}
					}

					.sub-actions {
						p {
							margin-bottom: .5em;
						}
					}
				}
			}
			&.photo-right {
				@include tablet {
					grid-template-columns: 1fr .5fr;
					.photo {
						order: 2;
					}
					.details {
						order: 1;
					}
				}
			}
		}
	}
}
