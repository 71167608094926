// 404 Page
.block-page-not-found {
    margin-top: 6em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-xsmall;
            width: 100%;
        }
    }
    .not-found-graphic-sun {
        margin: 0 auto;
        text-align: left;
        img {
            width: 19%;
            height: auto;
        }
    }
    .title {
        margin: 0 auto;
        font-family: $heading-font;
        font-size: 10rem;
        text-align: center;
        @include tablet {
            @include fontSize(12);
        }
    }
    .subtitle {
        margin: 0 auto 1em;
        font-size: 1.8rem;
        text-align: center;
    }
    .not-found-graphic-wheat {
        margin: 0 auto 1.5em;
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    .body {
        margin: 0 auto 1.5em;
        text-align: center;
        > *:last-child {
            margin: 0 auto;
        }
    }
    .actions {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        width: 100%;
        max-width: 250px;
        gap: 2em 0;
        @include tablet {
            max-width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0 2em;
        }
        li > a {
            width: 100%;
        }
    }
}
