div[data-inline-editor] {
	min-width: 300px;
}

.split-cols {
	padding: 0;

	&.curve-top-green-with-line {
		margin-top: 5vw;

		&:before {
			position: relative;
			top: calc(-5vw + 1px);
			bottom: unset;
		}
	}

	&.curve-bottom-green-no-line {
		margin-bottom: 5vw;

		&:after {
			position: relative;
			bottom: calc(-5vw + 1px);
			top: unset;
		}
	}

	@include mobile {
		margin-bottom: 4em;
	}


	.content & {
		padding: 0;
	}

	> .content {
		padding: 0 $mobile-padding;
		@include max-wrap;

		@include tablet {
			justify-content: space-between;
			display: flex;
			flex-wrap: wrap;

			> div {
				min-width: calc(50% - 40px);
				max-width: calc(50% - 40px);
			}
		}

		@include mobile {
			ul, ol {
				text-align: left;
			}
		}

		.col-right {
			a {
				display: grid;
				max-width: max-content;
				margin: 0 auto;
			}
		}
	}

	&.contactFormSection {
		> .content {
			@include tablet {
				.col-left {
					min-width: calc(70% - 40px);
					max-width: calc(70% - 40px);
				}

				.col-right {
					min-width: calc(30% - 40px);
					max-width: calc(30% - 40px);
				}
			}
		}
	}
}

.three-col {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;
	> .content {
		@include max-wrap;

		@include tablet {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			> div {
				width: 30%;
				max-width: 30%;

			}
		}
	}
}

.sidebar-cols {
	padding: 0 $mobile-padding;
	margin-bottom: 5em;

	> .content {
		@include max-wrap;
		display: grid;
		grid-gap: 30px;

		@include tablet {
			grid-template-columns: 1fr minmax(30%, 420px);
			grid-gap: 50px;
		}



		.main-col {
			order: 2;

			@include tablet {
				order: 1;
			}
		}

		.sidebar-col {
			order: 1;

			form .oac-btn {
				width: 100%;
			}

			@include tablet {
				order: 2;
				//border-left: solid 1px #CCC;
				padding-left: 50px;
			}
		}
	}
}


.fluid-area {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;

	> .content, > .content > .ktc-widget-zone {
		@include max-wrap;

		@include tablet {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;


			> div {
				margin: 0 40px 80px;
			}
		}
	}

	.ktc-editable-area & {
		> .content {
			display: block !important;

			> div {
				margin: 0 !important;
			}
		}
	}


	.break, .large-break {
		margin: 0 !important;
	}

	@include maxResponsive(1599px) {
		.break {
			flex-basis: 100%;
			height: 0;
		}
		.large-break {
			display: none;
		}
	}

	@include responsive(1600px) {
		.large-break {
			flex-basis: 100%;
			height: 0;
		}
		.break {
			display: none;
		}
	}
}



.force-white {
	* { color: $white !important; }
}





.search-results {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;

	> .content {
		@include max-wrap-small;
	}

	.search-item {
		margin-bottom: 2em;
		display: flex;

		.search-image {
			max-width: 200px;
			margin-right: 30px;
			display: none;

			&:empty {
			}
		}

		.search-item-content {
			h3 {
				margin-bottom: 0;
			}
		}
	}

	.pagination-container {
		text-align: center;

		.pagination {
			margin: 0;
			padding: 0;
			list-style: none;
			display: inline-flex;
			flex-wrap: wrap;

			a, span {
				display: block;
				padding: .5em;
			}
		}
	}
}



.accordion-header {
	cursor: pointer;
}




.sitemap {
	padding: 0 $mobile-padding;
	> .content {
		@include max-wrap-small;
		@include tablet {
			> ul {
				column-count: 2;
				column-gap: 50px;
			}
		}
	}
}

.check {
	width: 30px;
	height: 30px;
	display: inline-block;
	background: url(../images/ico-checkmark.svg) no-repeat;
	background-size: contain;
	background-position: center;

	@include mobile {
		text-align: right;
	}

	.force-white & {
		filter: brightness(100);
	}
}


#privacy-table table {
	border: solid 1px #CCC;
	border-width: 1px 0 0 1px;
	width: 100%;
	@include fontSize(1.8);
	margin-bottom: 1em;

	th {
		background: $yellow-background-color;
	}

	td, th {
		border: solid 1px #CCC;
		border-width: 0 1px 1px;
		padding: .5em;
	}
}



.sitemap {
	margin-bottom: 4em;
	.sitemap-menu {
		ul {
			padding-left: 1.5em;
		}

		li {
			margin-bottom: .5em;
		}

		@include tablet {
			column-count: 2;
			column-gap: 100px;
		}
	}
}


.page-not-found {
	.not-found-img {
		width: 100%;
		margin-bottom: 3em;
		margin-top: 7em;
	}

	> .content {
		@include max-wrap-small;
		text-align: center;
		padding: 0 $mobile-padding;

		h1 {
			margin-bottom: .5em;
			max-width: 600px;
			margin: 0 auto 1em;
		}

		.copy {
			max-width: 600px;
			margin: 0 auto 2em;
		}

		.actions {
			display: grid;
			justify-content: center;
			grid-template-columns: repeat(auto-fit,minmax(200px,max-content));
			grid-gap: 10px;
			margin-bottom: 7em;
		}
	}
}
