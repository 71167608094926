.explore-area {
	padding: 5em $mobile-padding;
	background: $primary-color;
	color: $white;
	width: 100%;
	margin-bottom: 5em;

	&.cross-sell-area {
		padding: 0 $mobile-padding;
	}

	> .content {
		@include max-wrap;
	}

	.intro {
		margin-bottom: 3.5em;
		text-align: center;
		@include max-wrap-small;

		> h2 {
			margin-bottom: 1em;

			@at-root .bg-brown#{&} {
				color: $white;
			}
		}

		.copy {
			margin-bottom: 3em;
		}
	}

	.ktc-section &:not(.cross-sell-area,.related-services-area) .blocks {
		display: block !important;

		> .ktc-widget-zone > .ktc-admin-ui {
			display: none;
		}

		> .ktc-widget-zone--empty > .ktc-admin-ui {
			display: block !important;
		}
	}

	.blocks, .blocks > .ktc-widget-zone {

		> .ktc-widget-zone {
			width: 100%;
		}

		align-items: flex-start;
		display: grid;
		grid-gap: 40px;

		@include tablet {
			grid-gap: 80px;
			grid-template-columns: repeat(auto-fit,minmax(200px,calc(33.333% - 54px)));

			@at-root .icon-rows-6#{&} {
				grid-gap: 5em;
				grid-template-columns: repeat(auto-fit,minmax(min-content,150px));
			}
		}

		grid-template-rows: auto;
		justify-content: center;


		.icon-block {
			opacity: 0;

			@at-root .active#{&}, &.active {
				animation: card-in .5s linear;
				animation-fill-mode: forwards;
				transform: translateY(50px);
				animation-delay: 1000ms;

				@for $i from 1 through 21 {
					&:nth-child(#{$i}) {
						animation-delay: $i * 200ms;
					}
				}
			}
		}
	}

	@keyframes card-in {
		0% {
			opacity: 0;
			transform: translateY(50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	.sub-actions {
		margin-top: 3em;
		text-align: center;

		&:empty {
			display: none;
		}

		a {
			display: inline-block;
			/*margin: 0 1em 1em 0;*/
		}
	}
}

.icons-block {
	text-align: left;
	transition: transform .5s ease;

	&.resource-block {
		text-align: left;
		justify-content: left !important;
		align-items: flex-start !important;

		.info {
			text-align: left !important;
		}

		.actions {
			justify-content: left;
			text-align: left;
			grid-gap: 0;

			a {
				@include fontSize(2);
			}
		}
	}

	img {
		transition: transform .5s ease;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}

	> img {
		margin-bottom: 1em;
	}

	h3 {
		margin-bottom: .3em;
	}

	.copy {
		margin-bottom: 1.5em;
	}

	.actions {
		display: grid;
		grid-gap: .7em;
		justify-content: center;

		@include fontSize(2.5);
	}


	&.horizontal {
		display: grid;
		grid-template-columns: 60px 1fr;
		text-align: left;
		align-items: center;
		grid-gap: 15px;

		> img {
			width: 50px;
			margin: 0;
		}

		.info {

			h3 {
				margin-bottom: .7em;
				color: $white;
			}

			.copy {
				@include fontSize(1.8);
				margin-bottom: 1em;
			}

			.actions {
				display: block;
				@include fontSize(2);

				a {
					display: block;
					line-height: 1.3;
				}
			}
		}
	}

	&.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 270px;
		margin: 0 auto;

		> img {
			width: 130px;
			margin-bottom: 1.5em;
		}

		.info {
			text-align: center;
		}

		.h3 {
			margin-bottom: .5em;
		}

		.copy {
			margin-bottom: 1em;
		}

		.actions {
			a {
				display: block;
				line-height: 1.4;
				margin-bottom: .5em;
			}
		}
	}

	&.photo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;

		img {
			width: 100%;
			margin-bottom: 1.5em;
			border-radius: 15px 0;
		}

		.info {
			text-align: center;
		}

		.h3 {
			margin-bottom: .5em;
		}

		.copy {
			margin-bottom: 1em;
		}

		.actions {
			a {
				display: block;
				margin-bottom: .5em;
			}
		}
	}
}