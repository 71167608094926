.block-icon-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
        }
    }
    .title {
        margin: 0 auto 1.5em;
        text-align: center;
    }
    .icons {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        li {
            margin: 0 0 2em;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: 0 1em;
        }
        .icon {
            width: 68px;
            height: auto;
            min-width: 68px;
            max-width: 68px;
            flex-basis: 68px;
        }
        .text {
            margin: 0;
            color: $body-font-color;
            font-size: 1.8rem;
            @include tablet {
                @include fontSize(2.4);
            }
            > * {
                margin: 0;
                color: $body-font-color;
                font-size: 1.8rem;
                @include tablet {
                    @include fontSize(2.4);
                }
            }
        }
        a {
            color: $body-link-color;
            font-weight: bold;
            font-size: 1.8rem;
            text-decoration: none;
            display: inline-block;
            @include tablet {
                @include fontSize(2.4);
            }
            &.hovered, &:focus, &:hover {
                text-decoration: underline;
            }
        }
    }
    &:not(.editmode) {
        .icons {
            @include tablet {
                column-count: 2;
                gap: 2em;
                break-inside: avoid;
                page-break-inside: avoid;
            }
            li {
                break-inside: avoid;
                page-break-inside: avoid;
            }
        }
    }
}
