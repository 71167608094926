.tile-area {

	> .content, > .content > .ktc-widget-zone {

		> .ktc-widget-zone {
			width: 100%;

			&:before, &:after {
				content: none;
			}
		}

		@include tablet {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	.ktc-section & > .content {
		display: block !important;

		> .ktc-widget-zone > .ktc-admin-ui {
			display: none;
		}

		> .ktc-widget-zone--empty > .ktc-admin-ui {
			display: block !important;
		}
	}

	.tile-block {
		background-size: cover;
		background-position: top center;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 $mobile-padding;
		overflow: hidden;
		position: relative;
		height: 100vw;

		@include tablet {
			height: 33.3333vw;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: inherit;
			top: 0;
			left: 0;
			z-index: 0;
			transform: scale(1);
			transition: transform 2s linear;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,.5);
			top: 0;
			left: 0;
			z-index: 1;
		}

		h2 {
			@include fontSize(4);
			max-width: 430px;
			color: $white;
			min-width: 200px;
			text-align: center;
			position: relative;
			z-index: 2;
			font-weight: 700;
			transform: translateY(38px);
			line-height: 1.2;

			&:before {
				content: '';
				width: 140px;
				height: 45px;
				display: block;
				position: absolute;
				top: -60px;
				left: 50%;
				transform: translateX(-50%);
				background-image: url(../images/ico-green_mountain.svg);
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.action {
			position: absolute;
			bottom: 0;
			z-index: 3;
			left: 0;
			width: 100%;

			@include tablet {
				transform: translateY(100%);
				transition: all .5s ease;
			}

			a {
				background: $secondary-color;
				border-radius: 0;
				color: $white;
				padding: .5em;
				text-align: center;
				display: block;
				font-weight: 700;
			}
		}

		&:hover {
			&:after {
				transform: scale(1.3);
				transform-origin: center;
				transition: transform 5s linear;
			}

			.action {
				transform: translateY(0%);
			}
		}
	}
}
