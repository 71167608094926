.block-calculator-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            display: grid;
            gap: 3em 0;
            align-items: flex-start;
            @include tablet {
                grid-template-columns: 1fr 1fr;
                gap: 0 3em;
                margin-bottom: 5em;
            }
            > * {
                width: 100%;
            }
        }
    }
    .intro {
        display: grid;
        gap: 1rem 0;
        > * {
            margin: 0 auto;
            width: 100%;
        }
        icon {}
        .title {}
        .description {
            > *:last-child {
                margin: 0 auto;
            }
        }
    }
    .calc-list {
        margin: 0 auto;
        padding: 2.5em 0 0 0;
        @include tablet {
            padding: calc(2em + 66px) 0 0 0;
        }
        .calc-accordion {
            border-bottom: 1px $notice-color solid;
            &:first-child {
                border-top: 1px $notice-color solid;
            }
            &.active .calc-accordion-button:after {
                transform: scale(1, -1);
                transition: transform 0.5s ease;
            }
        }
        .calc-accordion-button {
            margin: 0 auto;
            padding: 0.65em 50px .65em 10px;
            position: relative;
            width: 100%;
            text-align: left;
            &:after {
                content: "";
                display: inline-block;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                right: 10px;
                top: calc(50% - 7px);
                width: 30px;
                height: 15px;
                background: url('../Images/header_nav_dropdown-arrow.svg') transparent no-repeat center / 30px 15px;
                transform: scale(1, 1);
                transition: transform 0.5s ease;
            }
        }
        .calc-accordion-content {
            margin: 0;
            padding: 20px 10px;
            display: none;
            > *:last-child {
                margin: 0 auto;
            }
            .body {
                margin-bottom: 2em;
            }
            .cTimeValue {
                h2 {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
