.events-area {
	margin-bottom: 5em;
	padding: 0 $mobile-padding;

	&.center {
		text-align: center;
	}


	.content & {
		padding: 0;
	}

	> .content {

		@include max-wrap;
		max-width: 1400px;

		> h2 {
			margin-bottom: 1em;
		}

		> .copy {
			@include fontSize(2);
			margin-bottom: 2em;

			ul {
				max-width: max-content;

				@include tablet {
					column-count: 2;
					column-gap: 80px;

					@at-root .cols-one#{&} {
						column-count: 1;
					}

					@at-root .cols-three#{&} {
						column-count: 3;
					}

					ul {
						text-align: left;
						column-count: 1;
					}
				}

				text-align: left;

				@at-root .center#{&} {
					margin: 0 auto 2em;

					ul {
						margin: 0 0 2em 0;
					}
				}
			}
		}



		.events {
			.event {
				margin-bottom: 5em;
				text-align: left;
				display: grid;

				@include tablet { grid-template-columns: .5fr 1fr; }
					grid-column-gap: 30px;

					.event-dates {
						display: flex;
						margin-bottom: 2em;

						.cal-date {
							background: url(/Content/Images/ico-calendar.svg) no-repeat;
							background-size: contain;
							width: 100px;
							height: 110px;
							position: relative;

							&.end-date {
								margin-left: 70px;

								&:before {
									content: '-';
									color: $tertiary-color;
									font-size: 5.5rem;
									position: absolute;
									left: -44px;
									top: 50%;
									transform: translateY(-50%);
								}
							}

							.date-month {
								display: block;
								font-size: 1.4rem;
								color: $tertiary-color;
								text-align: center;
								margin-top: 18px;
								font-weight: 700;
								margin-bottom: 2px;
							}

							.date-day {
								display: block;
								font-size: 4.5rem;
								font-weight: 700;
								color: $tertiary-color;
								text-align: center;
								line-height: 1.3;
							}
						}
					}

					.info {
						h2 {
							margin-bottom: .5em;
						}

						.copy {
							@include fontSize(1.8);
						}
					}
				}
			}
		}
	}
