.block-cross-sell {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
            display: grid;
            gap: 5em 0;
            @include tablet {
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0 3rem;
            }
        }
    }
    .cs-title {
        margin: 0 auto;
        padding: 0;
        @include tablet {
            padding: 2em 0 0;
        }
    }
    .cs-item {
        margin: 0 auto;
        ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            text-align: center;
            display: grid;
            gap: 1rem 0;
            @include tablet {
                gap: 2.5rem 0;
            }
            li {
                margin: 0 auto;
                color: $body-link-color;
                @extend .f-h4;
                img {
                    width: 100%;
                    max-width: 176px;
                    height: auto;
                }
            }
            .cs-link {
                font-family: $body-font;
                color: $primary-color;
                font-size: 2rem;
                text-decoration: underline;
                @include tablet {
                    @include fontSize(2);
                }
            }
        }
    }
}
