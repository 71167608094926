.community-section {
	position: relative;
	z-index: 5;
	text-align: center;
	margin-bottom: 4em;

	.hydrated & {
		padding-top: 40px;
	}

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: calc(100% - 250px);
		position: absolute;
		top: 100px;
		left: 0;
		z-index: 1;
		background-image: var(--communitybackground);
		background-size: cover;
		background-position: bottom;
	}

	&:before {
		content: '';
		width: 100%;
		display: block;
		height: calc(100% - 250px);
		position: absolute;
		top: 100px;
		left: 0;
		z-index: 2;
		background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(251,251,251,0.09707633053221287) 51%, rgba(249,249,249,0.1026785714285714) 100%);
	}

	.ktc-section & .cards, .ktc-section & .stats {
		display: block !important;

		> .ktc-widget-zone > .ktc-admin-ui {
			display: none;
		}

		> .ktc-widget-zone--empty > .ktc-admin-ui {
			display: block !important;
		}
	}

	> .content {
		position: relative;
		z-index: 2;

		.cards, .cards > .ktc-widget-zone {

			> .ktc-widget-zone {
				width: 100%;

				&:before, &:after {
					content: none;
				}
			}

			padding: $mobile-padding $mobile-padding 0;

			@include tablet {
				margin-top: -100px;
				padding: 0;
			}

			margin-bottom: 4em;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(300px, 390px));
			grid-gap: 34px;
			justify-content: center;

			.card {
				background: $white;
				box-shadow: rgba(0,0,0,.4) 0 0 5px 0;
				border-radius: 15px;
				text-align: center;
				padding: 1.5em;
				transition: all .5s ease;

				&.no-filter:hover img {
					filter: none;
				}

				&:hover {
					background: $secondary-color;
					color: $white !important;

					h3, h2, .copy, a {
						color: $white !important;
					}

					img {
						filter: brightness(200);
					}
				}

				.icon {
					margin-bottom: .7em;
				}

				.info {
					h3 {
						margin-bottom: 0;
						color: $primary-color;
					}

					h2 {
						margin-bottom: .3em;
						color: $secondary-color;
					}

					.copy {
						margin-bottom: .5em;
					}

					.actions {
					}
				}
			}
		}
	}

	.stat-area {
		color: $white;
		padding: 0 $mobile-padding;

		@include tablet {
			padding: 0;
		}

		text-shadow: 0px 2px 2px rgba(0,0,0,.6);
		margin-bottom: 5em;

		h2 {
			margin-bottom: 1em;
			@include max-wrap-small;
			color: $white;
		}

		.copy {
			@include max-wrap-small;
			margin-bottom: 2em;
		}

		.stats, .stats > .ktc-widget-zone {

			> .ktc-widget-zone {
				width: 100%;

				&:before, &:after {
					content: none;
				}
			} 

			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(300px, 390px));
			grid-gap: 34px;
			justify-content: center;

			.stat-box {
				text-align: center;

				h3 {
					margin-bottom: 0;
				}
			}
		}
	}

	.scrolling-photos {
		img {
			border-radius: 15px;
		}
	}
}
