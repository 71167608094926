.mod-compare-table {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            display: grid;
            gap: 2em 0;
        }
    }
    .table-controls-arrows {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 50px calc(100% - 100px) 50px;
        align-content: center;
        align-items: center;
        width: 100%;
        @include tablet {
            display: none;
        }
        li {
            margin: 0;
            padding: 0;
            &:first-child {
                justify-self: left;
            }
            &:nth-child(2) {
                text-align: center;
                justify-self: center;
            }
            &:last-child {
                justify-self: right;
            }
        }
        .account-title {
            margin: 0;
            font-family: $heading-font;
            color: $body-link-color;
            font-weight: bold;
            font-size: 2.1rem;
        }
        .account-arrow-button {
            margin: 0;
            padding: 0;
            display: block;
            width: 50px;
            height: 50px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 26px 48px;
            &.prev-button {
                background-image: url("../Images/body_carousel-arrow.svg");
            }
            &.next-button {
                background-image: url("../Images/body_carousel-arrow.svg");
                transform: rotate(180deg);
            }
        }
    }
    table {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }
    tr {
        &:first-child {
            display: none;
            @include tablet {
                display: table-row;
                border-bottom: 1px $notice-color solid;
                th {
                    margin: 0 auto;
                    font-family: $heading-font;
                    color: $body-link-color;
                    font-weight: bold;
                    font-size: 2.1rem;
                    line-height: 1;
                    @include tablet {
                        font-size: 2.4rem;
                    }
                    text-align: center;
                }
            }
        }
        &:not(:first-child) {
            td:first-child {
                display: table-cell;
                font-size: 2.0rem;
            }
            td:not(:first-child) {
                display: none;
                &.active {
                    display: table-cell;
                }
                @include tablet {
                    display: table-cell;
                }
            }
        }
    }
    th {
        margin: 0;
        padding: 1em;
        text-align: left;
    }
    td {
        margin: 0;
        padding: 1em 0;
        text-align: center;
        font-size: 1.6rem;
        font-weight: normal;
        @include responsive(400px) {
            padding: 1em;
        }
        &:first-child {
            font-weight: bold;
            text-align: left;
            font-size: 1.8rem;
            @include tablet {
                text-align: center;
            }
        }
        span.mobile-title {
            display: none;
        }
    }
    .table-controls-dots {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        height: calc(25px + 2em);
        min-height: calc(25px + 2em);
        @include tablet {
            display: none;
        }
        .carousel-dot {
            height: 25px;
            width: 25px;
            border: 2px $green-background-color solid;
            background: transparent;
            border-radius: 25px;
            vertical-align: top;
            &.is-active {
                background: $green-background-color;
            }
        }
    }
    .rate-disclosure {
        > *:last-child {
            margin: 0;
        }
    }
}
