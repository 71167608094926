.block-correspondent-banking-item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap-small;
            display: grid;
            gap: 3rem 0;
        }
    }
    .title {
        margin: 0 auto;
        width: 100%;
    }
    .body {
        margin: 0 auto;
        height: auto;
        position: relative;
        ul {
            margin: 0 auto 1.5em;
            padding: 0 0 0 1.5em;
            @include tablet {
                column-count: 2;
                column-gap: 3em;
            }
            li {
                margin: 0 0 1em;
                text-align: left;
                break-inside: avoid;
            }
        }
        > *:last-child {
            margin: 0 auto;
        }
    }
    .actions {
        margin: 0;
        padding: $mobile-padding 0 0 0;
        border-top: 1px $notice-color solid;
        text-align: center;
    }
    &.editmode {
        .body {
            max-height: auto !important;
            &:after {
                display: none !important;
            }
        }
    }
    &:not(.editmode):not(.expandable) {
        .body {
            max-height: auto;
        }
        .actions {
            display: none;
        }
    }
    &.expandable:not(.editmode) {
        .body {
            max-height: 8em;
            overflow: hidden;
            transition: max-height 0.5s ease-in-out;
            &:after {
                content: "";
                position: absolute;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 141px;
                left: 0;
                bottom: 0;
                pointer-events: none;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                transition: background 0.5s ease-in-out;
            }
            &.active {
                max-height: 100em;
                transition: max-height 0.5s ease-in-out;
                &:after {
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
                    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    transition: background 0.5s ease-in-out;
                }
            }
        }
        .actions {
            display: block;
            button {
                .open {
                    display: block;
                }
                .close {
                    display: none;
                }
                &.active {
                    .open {
                        display: none;
                    }
                    .close {
                        display: block;
                    }
                }
            }

        }
    }
}
