.block-title-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
    text-align: center;
    > .content {
        margin: 0 auto;
        @include max-wrap-small;
    }
    .section-title {
        margin: 0 auto .5em;
    }
    .support-text {
        margin: 0 auto;
        > *:last-child {
            margin: 0 auto;
        }
    }
}
