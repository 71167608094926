.mod-data-table {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .table-title {
        margin: 0 0 0.5em;
        text-align: center;
    }
    .effective-date {
        margin: 0 0 1.5em;
        font-size: 1.4rem;
        font-style: italic;
        text-align: center;
        @include tablet {
            font-size: 1.6rem;
        }
    }
    table {
        margin: 0 auto 3rem;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }
    tr {
        border-bottom: 1px $notice-color solid;
        &:first-child {
            display: none;
        }
        &:nth-child(2) {
            border-top: 1px $notice-color solid;
        }
        @include tablet {
            border-top: none;
            border-bottom: none;
            &:first-child {
                display: table-row;
                border-bottom: 1px $notice-color solid;
            }
            &:nth-child(2) {
                border-top: none;
            }
        }
    }
    th {
        margin: 0 auto;
        padding: 1em;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.3;
        text-align: center;
    }
    td {
        margin: 0;
        padding: 1em;
        display: block;
        text-align: left;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.3;
        @include tablet {
            display: table-cell;
            text-align: center;
        }
        ul {
            display: inline-block;
        }
        span.mobile-title {
            font-weight: bold;
            display: inline;
            @include tablet {
                display: none;
            }
        }
    }
    .rate-disclosure {
        @include max-wrap;
        max-width: 900px;
        margin: 0 auto 4rem;
        text-align: left;
    }
}
