.accordion-section {
	padding: 0 $mobile-padding;
	margin-bottom: 4em;
	text-align: center;
	background-color: $white;
	transition: background .5s ease;

	&.open {
		background: $primary-color;
		color: $white;
		padding-top: 2em;

		.close-btn {
			display: inline-block;
		}
	}

	.js-accordion-close {
		display: none;
	}

	> .content {
		@include max-wrap;
	}

	h2 {
		margin-bottom: .5em;
	}

	.copy {
		margin-bottom: 2em;
	}

	.js-accordion-header {
		@at-root .open#{&} {
			display: none;
		}
	}

	.close-btn {
		@extend .ghost-btn-white;
		width: 200px;
		padding: 0;

		&:before {
			content: '\00D7';
			display: inline-block;
			margin-right: 5px;
			font-size: 2em;
			vertical-align: middle;
			font-weight: normal;
			color: $white;
			position: relative;
		}

		@at-root .open#{&} {
			display: inline-block;
		}
	}

	.accordion-content {
		display: none;
	}
}
