.block-featured-content-side-image {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    @include tablet {
        &.text-left {
            .image {
                order: 2;
            }
            .text {
                order: 1;
            }
        }
        &.text-right {
            .image {
                order: 1;
            }
            .text {
                order: 2;
            }
        }
    }
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            display: grid;
            gap: 5em 0;
            @include tablet {
                grid-template-columns: 1fr 1fr;
                align-items: center;
                gap: 5em 5em;
            }
        }
    }
    .image {
        svg {
            width: 100%;
            height: auto;
        }
    }
    .text {
        display: grid;
        gap: 2em;
        > * {
            margin: 0 auto;
            width: 100%;
        }
        .title {

        }
        .body {
            > *:last-child {
                margin: 0 auto;
            }
        }
        .actions {
            padding: 0;
            list-style: none;
            display: grid;
            gap: 2em 0;
        }
        .disclosure {
            > *:last-child {
                margin: 0 auto;
            }
        }
    }
}
